module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mic Drop Website","short_name":"Mic Drop","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"standalone","icon":"./src/images/favicon.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9802ba232403f03c952514b26508970f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/getmicdrop.com/getmicdrop.com/src/components/NautilusWrapper/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"getmicdrop.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/getmicdrop.com/getmicdrop.com/src/components/MDXLayout/index.js"},"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/getmicdrop.com/getmicdrop.com/node_modules/gatsby-remark-images","id":"2e364750-fffd-5fdd-83a6-9aa8142f9581","name":"gatsby-remark-images","version":"6.5.1","modulePath":"/home/runner/work/getmicdrop.com/getmicdrop.com/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1280},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/runner/work/getmicdrop.com/getmicdrop.com/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.5.0","modulePath":"/home/runner/work/getmicdrop.com/getmicdrop.com/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/getmicdrop.com/getmicdrop.com/node_modules/gatsby-remark-smartypants","id":"d48194e5-b344-5f22-b93d-76045ee44e88","name":"gatsby-remark-smartypants","version":"5.5.0","modulePath":"/home/runner/work/getmicdrop.com/getmicdrop.com/node_modules/gatsby-remark-smartypants/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/getmicdrop.com/getmicdrop.com","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280},
    }]
