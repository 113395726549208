import { Icon } from '@octopusthink/nautilus';
import DownloadLink from 'components/DownloadLink';
import Image from 'components/Image';
import Panel from 'components/Panel';
import { downloadURL } from 'utils/downloadURL';
import * as React from 'react';
export default {
  Icon,
  DownloadLink,
  Image,
  Panel,
  downloadURL,
  React
};