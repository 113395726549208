import { Link, Icon, VisuallyHidden } from '@octopusthink/nautilus';
import DownloadLink from 'components/DownloadLink';
import Panel from 'components/Panel';
import { downloadURL } from 'utils/downloadURL';
import * as React from 'react';
export default {
  Link,
  Icon,
  VisuallyHidden,
  DownloadLink,
  Panel,
  downloadURL,
  React
};